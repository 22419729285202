<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Unit Group</h3>
            <hr class="m-t-0 m-b-40" />

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    Unit
                  </label>
                  <div
                    :class="['col-md-9', { invalid: dropdown.isinvalidInduk }]"
                  >
                    <Multiselect
                      placeholder="Type to Search"
                      select-label
                      :value="payload.parent"
                      track-by="value"
                      label="text"
                      :options="dropdown.parentList"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeParent"
                      @search-change="parentFind"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="dropdown.isinvalidInduk"
                    ></label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <label class="col-md-3 text-right"> Group Unit </label>
                  <div
                    class="col-md-9 text-right"
                    :class="[{ invalid: isInvalidUnitKerja }]"
                  >
                    <Multiselect
                      v-model="payload.unit"
                      label="text"
                      track-by="value"
                      :placeholder="$t('choose_receiver_unit')"
                      open-direction="bottom"
                      :options="filteredUkerList"
                      :value="dropdownUnitKerja.value"
                      :searchable="true"
                      :loading="dropdownUnitKerja.isLoadingUnitKerja"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      @input="onChangeUnitKerja"
                      @close="onTouchUnitKerja"
                      @search-change="unitKerja"
                    >
                      <span slot="noResult">{{ $t("data_not_found") }}</span>
                    </Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidUnitKerja"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button
                      type="submit"
                      class="btn btn-success"
                      title="Submit"
                    >
                      Submit</button
                    >&nbsp;
                    <button
                      type="button"
                      @click="goBack()"
                      class="btn btn-inverse"
                      title="Cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
  components: {
    Input,
    Multiselect,
    RotateSquare5,
  },
  computed: {
    isInvalidUnitKerja() {
      return (
        this.dropdownUnitKerja.isTouched &&
        this.dropdownUnitKerja.value.length === 0
      );
    },
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.unitGroup;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    filteredUkerList() {
      let list = this.dropdownUnitKerja.ukerList;
      return list;
    },
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      payload: {
        parent: [],
        unit: [],
      },
      dropdown: {
        parentList: [],
        isinvalidInduk: false,
      },
      dropdownUnitKerja: {
        isTouched: false,
        ukerList: [],
        value: [],
        isLoadingUnitKerja: false,
      },
      isDisabled: false,
      isLoading: false,
    };
  },
  async mounted() {
    var query = "";
    this.parentFind(query);
    this.initialize();
    this.unitKerja(query);
  },

  methods: {
    onChangeUnitKerja(value) {
      this.dropdownUnitKerja.value = value;
    },
    onTouchUnitKerja() {
      this.dropdownUnitKerja.isTouched = true;
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/unit-group/${this.$route.params.id}`).then((response) => {
            const state = {
              loaded: true,
            };
            this.$store.commit("unitGroup/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("unitGroup/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        unit: data.unit,
        parent: data.parent,
      };
      this.dropdownUnitKerja.value = data.unitPos;
    },
    unitKerja(query) {
      const mv = this;
      mv.dropdownUnitKerja.isLoadingUnitKerja = true;
      let address = "";
      address = `units/unit_group?s=${query ? query : ""}`;
      axios
        .get(address)
        .then((res) => {
          mv.dropdownUnitKerja.ukerList = res.data.items;
          mv.dropdownUnitKerja.isLoadingUnitKerja = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            mv.$store.dispatch("auth/logout");
          }
        });
    },
    onChangeParent(value) {
      this.payload.parent = value;
    },
    parentFind(query) {
      this.isLoading = true;
      axios
        .get(`units/list?s=${query}`)
        .then((res) => {
          this.dropdown.parentList = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("unitGroup/onCancel");
    },
    submit() {
      const payload = {
        id: this.$route.params.id,
        parent: parseInt(this.payload.parent.value),
        unit: parseInt(this.payload.unit.value),
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("unitGroup/submitEdit", payload);
          } else {
            this.$store.dispatch("unitGroup/submitAdd", payload);
          }
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

